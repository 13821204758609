import { defaultFiltersObject } from "./helpers.js"
import { cloneDeep } from "lodash-es"

export default () => ({
  SET_ITEM: (state, item) => (state.item = item),

  SET_INITIAL_ITEM: (state, item) => (state.initialItem = item),

  RESET_ITEM: state => (state.item = cloneDeep(state.initialItem)),

  RESET_FILTERS: state => (state.filters = defaultFiltersObject())
})
