import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { map, get, isUndefined } from "lodash-es"
import { format as dateFormat } from "date-fns"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
import { defaultFiltersObject, defaultPlanObject } from "./helpers.js"

import extractMutations from "./mutations"
import extractActions from "./actions"

const mappedDateRange = ([start, end]) => {
  if (isUndefined(start) || isUndefined(end)) {
    return null
  } else {
    return {
      start: dateFormat(start, DATE_FNS_DATE_FORMAT),
      end: dateFormat(end, DATE_FNS_DATE_FORMAT)
    }
  }
}

const mapSaleStatus = saleStatus => {
  switch (get(saleStatus, "id")) {
    case "selling":
      return true
    case "stop_selling":
      return false
    default:
      return undefined
  }
}

const mapFilters = filters => {
  return {
    ota_ids: map(filters.otas, "id"),
    shop_ids: map(filters.shops, "id"),
    car_class_ids: map(filters.carClasses, "id"),
    date_range: mappedDateRange(filters.dateRange),
    period_inclusion_type: get(filters.periodInclusion, "id"),
    period_type: get(filters.periodType, "id"),
    search_value: filters.searchValue,
    released: mapSaleStatus(filters.saleStatus)
  }
}

const baseURI = "/companies/plans"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch", "delete"],
  withFilters: defaultFiltersObject(),
  withSorting: true,
  withPagination: true,
  mapFilters
})

store.mergeState({
  item: defaultPlanObject(),
  initialItem: defaultPlanObject()
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
